<template>
  <div
    class="a-main__detail-list"
    v-bind:class="[
      Object.keys(this.sleepData).length && this.sleepData.eventOccurTime.length
        ? 'tableDiv'
        : '',
    ]"
    style="color: white"
  >
    <table
      v-if="
        Object.keys(this.sleepData).length &&
        this.sleepData.eventOccurTime.length
      "
      class="table"
    >
      <tbody
        class="parentTableBody"
        v-bind:class="[this.expandedIndex == -1 ? 'flexStyle' : 'normal']"
      >
        <tr v-for="(d, k) in computeGroupBY" :key="k" v-scroll-to="'#ex-' + k">
          <td
            v-if=" $props.sleepData.data.filter(value => value.x == k).length &&
            $props.sleepData.data.filter(value => value.x == k)[0]['apneaEvents'] > 0"
            style="
              border-top: 1px solid #dee2e6;
              border-bottom: 1px solid #dee2e6;
            "
            :id="'ex-' + k"
          >
            <tr>
              <td
                class="parentDate"
                style="width: 40%; cursor: pointer"
                @click="onDateClick(k)"
              >
                {{ d[0]["date"] | moment }}
              </td>
              <td class="parentDay" style="width: 20%; cursor: pointer">
                {{ d[0]["date"] | day }}
              </td>
              <td style="width: 30%; cursor: pointer" @click="onDateClick(k)">
                {{ $props.sleepData.data.filter(value => value.x == k)[0]['apneaEvents'] }} 
                {{ $props.sleepData.data.filter(value => value.x == k)[0]['apneaEvents']  > 1 ? "events" : "event" }}
              </td>
              <td
                style="width: 10%"
                class="expandCollapse"
                v-bind:class="[ $props.sleepData.data.filter(value => value.x == k)[0]['apneaEvents']  > 0 ? 'red' : 'green']"
                v-if="k == expandedIndex"
                @click="onDateClick(k)"
              >
                <font-awesome-icon icon="minus" />
              </td>
              <td
                class="expandCollapse"
                v-bind:class="[$props.sleepData.data.filter(value => value.x == k)[0]['apneaEvents'] > 0 ? 'red' : 'green']"
                v-else
                @click="onDateClick(k)"
              >
                <font-awesome-icon icon="plus" />
              </td>
            </tr>
            <table class="childTable" v-if="k == expandedIndex">
              <thead>
                <tr>
                  <th class="thDate"></th>
                  <th class="thDay"></th>
                  <th class="thTime"></th>
                  <th class="thSpo2"></th>
                </tr>
              </thead>
              <tbody class="childBody">
                <tr v-for="(dd, kk) in d" :key="kk">
                  <td>{{ dd.date }}</td>
                  <td>{{ dd.day }}</td>
                  <td>{{ dd.time }}</td>
                  <td class="spo2">{{ dd.spo2.toFixed() }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <div></div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  props: ["sleepData"],
  data() {
    return {
      expandedIndex: -1,
      data: [
        {
          date: "2019",
          items: [
            {
              name: "a",
            },
            {
              name: "x",
            },
          ],
        },
        {
          date: "2020",
          items: [
            {
              name: "b",
            },
            {
              name: "y",
            },
          ],
        },
      ],
    };
  },
  mounted() {},
  filters: {
    moment: function (date) {
      return moment(date).format("MMM Do YYYY");
    },
    day: function (date) {
      return moment(date).format("ddd");
    },
  },
  computed: {
    ...mapState(["loading"]),
    computeGroupBY() {
      var a = this._.groupBy(this.sleepData.eventOccurTime, function (n) {
        return n.date;
      });

      return a;
    },
  },
  watch: {
    computeGroupBY: {
      deep: true,
      handler: function (a) {
        this.expandedIndex = Object.keys(a)[0];
      },
    },
  },
  methods: {
    moment: function () {
      return moment();
    },
    onDateClick(k) {
      this.expandedIndex == k
        ? (this.expandedIndex = -1)
        : (this.expandedIndex = k);
    },
  },
};
</script>
<style scoped>
.a-main__back {
  text-align: left;
}
.account-setting {
  text-align: left;
}
.garmin-connect {
  cursor: pointer;
}

.sleep-summary {
  display: flex;
  font-size: 18px;
  font-style: bold;
  font-family: sans-serif;
}
.table {
  color: #dee2e6;
}
/* .table thead th {
  border-bottom: 1px solid #dee2e6;
} */

.childTable th {
  border-top: 0px !important;
  border-bottom: 0px !important;
  padding-bottom: 1px;
  padding-top: 1px;
}
.childBody td,
.childBody th {
  border-top: 1px solid #dee2e6;
  font-size: 13px;
}
.a-main__detail-list tbody {
  font-size: 13px;
}
.date-nav {
  display: flex;
}
.nav-active {
  color: white;
}
.spo2 {
  color: orange !important;
}
.fifteen {
  background: rgb(121, 172, 121);
  color: #000;
}

.sixty {
  background: rgb(238, 202, 141);
}
/* @media screen and (max-width: 340px) {
  
} */
@media screen and (max-width: 367px) {
  /* .parentTableBody td, */
  /* .parentTableBody th, */
  .parentTableBody td,
  .childBody td,
  .childBody th {
    font-size: 11px;
  }
  .thDate {
    width: 46% !important;
  }
  .thDay {
    width: 15% !important;
  }
  .thTime {
    width: 30% !important;
  }
  .thSpo2 {
    width: 20% !important;
  }
  .flexStyle .parentDate {
    width: 44%;
  }
  .flexStyle .parentDay {
    width: 0%;
  }
  .flexStyle .expandCollapse {
    width: 30%;
  }
}
.thDate {
  width: 39%;
}
.thDay {
  width: 15%;
}
.thTime {
  width: 31%;
}
.thSpo2 {
  width: 10%;
}

.childBody {
  /* background: #3e464e; */
  color: #fff;
}

.flexStyle {
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
}
.parentTableBody td {
  border-top: 0px solid #dee2e6;
}

.childBody td {
  border-top: 1px solid #8d8a7e;
}
/* 
.childTable thead{
 background: rgb(25, 117, 71);

} */
.tableTitle {
  /* text-decoration: underline; */
  color: #fff;
}
.tableDiv {
  height: 258px;
  overflow-y: scroll;
}
.expandCollapse {
  cursor: pointer;
}
.spo2 {
  color: rgb(141, 138, 132);
}

.tableDiv::-webkit-scrollbar {
  display: none;
}

.red {
  color: rgb(221, 70, 70);
}
.green {
  color: rgb(95, 233, 77);
}
</style>