<template>
  <div class="app">
    <apexcharts
      height="450"
      :options="chartOptions"
      :series="series"
    ></apexcharts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { calendarDateFormat } from "@/mixins/appMixins";
import moment from "moment";

export default {
  mixins: [calendarDateFormat],
  name: "SleepDataChart",
  components: {
    apexcharts: VueApexCharts,
  },
  props: ["sleepData", "min", "max", "view"],
  data: function () {
    return {
      count: 0,
      range: null,
      chartOptions: {
        chart: {
          id: "vuechart-example",
          width: "100%",
          type: "rangeBar",
          foreColor: "#dee2e6",

          animations: {
            enabled: true,
            easing: "linear",
            speed: 500,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              // customIcons: [],
              autoSelected: "zoom",
            },
          },
          selection: {
            enabled: true,
          },
          zoom: {
            enabled: true,
            type: "x",
            autoScaleXaxis: true,
            zoomedArea: {
              fill: {
                color: "#90CAF9",
                opacity: 0.4,
              },
              stroke: {
                color: "#0D47A1",
                opacity: 0.4,
                width: 1,
              },
            },
          },
        },
        fill: {
          colors: [
            function ({ value, seriesIndex, w }) {
              console.log(value);
              if (w.config.series[0].data[seriesIndex].apneaEvents < 1) {
                return "#21ff80";
              } else {
                return "#D9534F";
              }
            },
          ],
        },
        dataLabels: {
          enabled: false,
          textAnchor: "middle",
          offsetX: 0,
          offsetY: 0,
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            console.log(value);
            console.log(dataPointIndex);
            console.log(seriesIndex);
            console.log(w.config.series[0].data[dataPointIndex].apneaEvents);
            return w.config.series[0].data[dataPointIndex].apneaEvents > 0
              ? w.config.series[0].data[dataPointIndex].apneaEvents
              : "";
          },
        },
        yaxis: {
          show: true,
          showAlways: true,
          showForNullSeries: true,
          opposite: false,
          tickAmount: 19,
          min: -28800,
          max: 39600,
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-yaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => {
              return moment().startOf("day").seconds(value).format("hh:mm A");
            },
          },
          axisBorder: {
            show: false,
            color: "#dee2e6",
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#dee2e6",
            width: 6,
            offsetX: 0,
            offsetY: 0,
          },
          tooltip: {
            enabled: false,
            offsetX: 0,
          },
        },
        xaxis: {
          labels: {
            rotate: -0,
            hideOverlappingLabels: true,
              showDuplicates: false,
            formatter: function (value) {
              return moment(value).format("D");
            },
          },
          tickAmount: 6,
          tickPlacement: "on",
          tooltip: {
            enabled: true,
            offsetX: 0,
          },
        },

        responsive: [
          {
            options: {
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
        
        tooltip: {
          show: false,
          custom: function () {
            return null;
          },
        },
      },
      series: [
        {
          data: [],
        },
      ],
    };
  },
  mounted() {},
  watch: {
    sleepData: function () {
      this.updateChart();
    },

    // view: function(value) {
    //   console.log(value)
    //   if(value === 'week'){
    //     this.chartOptions.xaxis.tickAmount = 5
    //   }else {
    //      this.chartOptions.xaxis.tickAmount = 7
    //   }
    // }
  },
  methods: {
    updateChart() {
      const newData = this.sleepData.data;
      this.series = [
        {
          data: newData,
        },
      ];
    },
  },
};
</script>
<style scoped>
button {
  background: #26e6a4;
  border: 0;
  font-size: 16px;
  color: "#fff";
  padding: 10px;
  margin-left: 28px;
}

.apexcharts-tooltip {
  background: #f3f3f3;
  color: orange;
}
</style>

