<template>
  <div class="site-wrapper">
    <div class="a-main a-main--home bg-linear">
      <div class="container">
        <div class="a-head">
          <div class="a-head__back align-self-center">
            <router-link :to="{ path: 'home' }">
              <BackArrowSvg />
            </router-link>
          </div>
        </div>
        <div class="data-page-title mb__22 d-flex">
          <div class="data-page-title__img align-self-center">
            <SleepApneaSvg :width="41" :height="36" :viewBox="'0 0 36 31'" />
          </div>
          <div
            class="data-page-title__title color-white align-self-center ml-20"
          >
            <h2 class="page-title">Sleep Apnea</h2>
            <p
              v-show="this.view == 'week'"
              class="page-sub-title page-sub-title--one mb-0"
            >
              {{ this.sleeps.data ? totalApneaEvents : 0 }} Sleep Apnea Events
              in the Past 7 days.
            </p>
            <p
              v-show="this.view == 'all'"
              class="page-sub-title page-sub-title--two mb-0"
            >
              {{ this.sleeps.data ? totalApneaEvents : 0 }} Sleep Apnea Events
              in the Past 30 days.
            </p>
          </div>
        </div>
        <div class="data-chart">
          <div class="data-chart__toggler mb__22 d-flex justify-content-end">
            <div
              data-slide="data-chart__content--one"
              v-bind:class="[this.view == 'week' ? 'index-active' : '']"
              class="index-tab index-tab--one"
              @click="onViewClick('week')"
            >
              Week
            </div>
            <div
              class="index-tab index-tab--two"
              v-bind:class="[this.view == 'all' ? 'index-active' : '']"
              data-slide="data-chart__content--two"
              @click="onViewClick('all')"
            >
              Month
            </div>
          </div>

          <div class="data-chart__chart">
            <div class="data-chart__wrap">
              <SleepDataChart
                :view="this.view"
                :sleepData="this.sleeps"
                :min="this.min"
                :max="this.max"
              />
            </div>
          </div>
          <br />
        
        </div>
        <div class="expandTable">
        <SleepDataTableExpand :sleepData="this.sleeps" />
        </div>
        <!-- <br/>
        <br/> -->
        <!-- <div class="normalTable">
        <SleepDataTable :sleepData="this.sleeps" />
        </div> -->

        <Exercise />
        <FruitOfTheDay />
      </div>
    </div>
  </div>
</template>
<script>
import { calendarDateFormat, garminPromptMixin } from "@/mixins/appMixins";
import { mapActions, mapState } from "vuex";
import SleepDataChart from "../components/sleepApnea/chart/week/SleepDataChart"; 
// import SleepDataTable from "../components/sleepApnea/chart/week/SleepDataTable";
import SleepDataTableExpand from "../components/sleepApnea/chart/week/SleepDataTableExpand";
//import SleepApneaChart from "../components/sleepApnea/chart/week/SleepApneaChart";
// import DemoScroll from "../components/sleepApnea/chart/week/DemoScroll";
import Exercise from "../components/home/Exercise";
import FruitOfTheDay from "../components/home/FruitOfTheDay";
import SleepApneaSvg from "@/components/svg/SleepApneaSvg";
import BackArrowSvg from "@/components/svg/BackArrowSvg";
import moment from "moment";
export default {
  mixins: [calendarDateFormat, garminPromptMixin],
  components: {
    FruitOfTheDay,
    Exercise,
    SleepApneaSvg,
    BackArrowSvg,
    SleepDataChart,
    //  SleepDataTable,
    SleepDataTableExpand,
    // DemoScroll,
  },
  data() {
    return {
      showApneaChart: false,
      loadData: {},
      view: "week",
      sleepApneaData: {
        label: "Sleep Apnea",
        backgroundColor: "#ff5f3c",
        radius: 5,
        hoverRadius: 12,
        fill: false,
        //  pointStyle: "circle",
        data: {},
      },
      sleepApneaChartOptions: {
        scales: {
          yAxes: [
            {
              type: "time",
              time: {
                unit: "hour",
                stepSize: "1",
              },
              ticks: {
                fontColor: "#fff",
                // suggestedMin: 50,
                // suggestedMax: 100
              },
              gridLines: {
                display: true,
                color: "#d9d9d9",
              },
            },
          ],
          xAxes: [
            {
              type: "time",
              time: {
                unit: "day",
              },
              ticks: {
                fontColor: "#fff",
              },
              gridLines: {
                display: true,
                color: "#fff",
              },
              distribution: "series",
            },
          ],
        },
        tooltips: {
          backgroundColor: "#18b0b8",
          callbacks: {
            label: function (tooltipItem) {
              return (
                moment(tooltipItem.label).format("MMM D") +
                ", " +
                moment(tooltipItem.value).format("hh:mm  ")
              );
            },
          },
        },
        legend: {
          display: true,
          labels: {
            fontColor: "#f87979",
          },
        },
        scaleOverride: true,
        responsive: true,
        maintainAspectRatio: false,
      },
      min: 1,
      max: 7,
    };
  },
  computed: {
    ...mapState("garmin", ["sleeps", "garminStatus"]),
    ...mapState("user", ["profile"]),
    totalApneaEvents: function () {
      let total = 0;
      if (this.sleeps.data) {
        this.sleeps.data.map((item) => {
          total += item.apneaEvents;
        });
      }
      return total;
    },
  },
  async mounted() {
    await this.checkGarminStatus();
    if (this.garminStatus) {
      this.pullSleepData("week", "week");
    } else {
      this.promptGarminConnect();
    }
  },
  methods: {
    ...mapActions("garmin", ["pullSleep", "checkGarminStatus"]),
    ...mapActions(["setIsAuthenticated", "setLoading"]),
    init() {},
    onViewClick($type) {
      if (this.garminStatus) {
        let payload = $type === "all" ? "month" : "week";
        this.pullSleepData(payload, $type);
      } else {
        this.promptGarminConnect();
      }
      this.min = $type === "all" ? 7 : null;
      this.max = $type === "all" ? 7 : null;
    },
    async pullSleepData(payload, $type) {
      try {
        this.setLoading(true);
        this.showApneaChart = false;
        let res = await this.pullSleep(payload);
        this.sleepApneaData.data = this.sleeps.eventOccurTime;
        this.showApneaChart = true;
        if (!res.data) {
          this.$toaster.error(res.msg);
        } else {
          this.view = $type;
        }
        this.setLoading(false);
      } catch (e) {
        this.$toaster.error("Error fetching sleep data");
        this.setLoading("error");
      }
    },
  },
};
</script>
<style>
.expandTable {
  padding-bottom: 20px;
}
</style>